.customToast {
  z-index: 5000;
  position: absolute;
  height: 50px;
  background-color: red;
  color: white;
  bottom: 0;
  margin: 1rem;
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  line-height: calc(50px);
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: 400;
  font-size: 1.125rem;
}

.customToast .ToastIcon {
  margin-right: 20px;
}

#toastCloseButton {
  color: white;
  height: 25px;
  width: 25px;
  margin-left: 20px;
  padding: 0;
  font-size: 1rem;
}

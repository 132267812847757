.bulkEditContainer {
  width: 100%;
}

.bulkEditContainer .EditFab {
  position: absolute;
  right: 85px;
  bottom: 40px;
  font-size: 20px;
  width: 70px;
  height: 70px;
}

#backArrowBulkEdit {
  color: #2699fb;
  align-self: center;
  font-size: 30px;
  margin-right: 15px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.bulkEditContainer .bulkEditTabContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
}

.bulkEditTabContainer .bulkList {
  height: 100%;
  overflow-y: auto;
}

.bulkList .jobItemList {
  list-style-type: none;
  padding: 0;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bulkList .jobItemList li {
  position: relative;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #f0f2f8;
}

.bulkList .jobItemList li:hover {
  background: #f0f2f8;
}

.ais-SearchBox .ais-SearchBox-form {
  width: 100%;
  height: 50px;
  display: flex;
}

.ais-SearchBox .ais-SearchBox-form input {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
}

.ais-SearchBox .ais-SearchBox-form .ais-SearchBox-submit {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.8rem 0.8rem;
  font-size: 0.81rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  border: 0;
  border-radius: 0.125rem;
  cursor: pointer;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  background-color: #1976d2;
}
.ais-SearchBox .ais-SearchBox-form #search {
  color: white;
}
.ais-SearchBox .ais-SearchBox-form .ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox .searchBarExpand {
  position: absolute;
  right: 50px;
  font-size: 15px;
  margin-top: 5px;
}

#bulkSearchPopper {
  width: 1000px;
  height: 500px;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.bulkEditTabContainer .bulkEditTabBar {
  flex-direction: row;
}

.bulkEditTabContainer .bulkEditTabBar .MuiTabs-root {
  flex-grow: 1;
}

.ais-InstantSearch__root,
.ais-Hits {
  height: 100%;
  width: 100%;
}

ul.ais-Hits-list {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

ul.ais-Hits-list li {
  position: relative;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
}

ul.ais-Hits-list p {
  padding: 10px 20px 20px 10px;
  margin: 0px;
}

ul.ais-Hits-list p:hover {
  background: #f0f2f8;
}

ul.ais-Hits-list p.selected {
  position: relative;
  background: #bce0fd;
}

.searchable-list {
  display: flex;
  width: 100%;
}

.ais-Hits {
  height: calc(100% - 30px);
}

.popupFormMultiField {
  width: 100%;
}

.AlgoliaMultiSelect {
  display: flex;
}
.searchable-list .ais-InstantSearch__root .ais-Hits ul.ais-Hits-list {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.AlgoliaMultiSelect .searchable-list .ais-InstantSearch__root .ais-Hits ul.ais-Hits-list {
  position: relative;
  max-height: 300px;
  margin: 0;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.AlgoliaMultiSelect .AlgoliaMultiSelectList,
.AlgoliaMultiSelectListSelected {
  width: 50%;
}

.AlgoliaMultiSelectListSelected .ais-Hits-list {
  overflow: auto;
  max-height: 300px;
}

.AlgoliaMultiSelectListSelected .ais-Hits-list .ais-Hits-item {
  background-color: #2699fb;
  border: 2px solid white;
  border-radius: 5px;
  padding: 2px;
  width: auto;
  margin: 1.5px;
  color: white;
  position: relative;
}

.AlgoliaMultiSelectListSelected .ais-Hits-list .ais-Hits-item .SelectedPriceList {
  display: flex;
  flex-direction: row;
}

.AlgoliaMultiSelectListSelected .ais-Hits-list .ais-Hits-item .SelectedPriceList .Remove {
  padding: 2px 4px;
  float: right;
  right: 0;
  border-left: 2px solid white;
  position: absolute;
  text-align: center;
}
.AlgoliaMultiSelectListSelected .ais-Hits-list .ais-Hits-item .SelectedPriceList .Remove:hover {
  background-color: red;
}

.SelectAutoComplete ul {
  list-style-type: none;
  position: absolute;
  padding: 0;
  background: white;
  text-align: left;
  border-right: 1px solid #2699fb;
  border-bottom: 1px solid #2699fb;
  border-left: 1px solid #2699fb;
  z-index: 60;
  max-height: 300px;
  width: 100%;
  overflow: auto;
}

.SelectAutoComplete ul li {
  padding: 3px 0px;
  padding-left: 3px;
  cursor: pointer;
}

.SelectAutoComplete ul li:hover {
  background: #2699fb;
  color: white;
}

.SelectAutoComplete .AutoCompleteSelectInput {
  position: relative;
}

.searchable-list .ais-InstantSearch__root .ais-SearchBox .ais-SearchBox-form {
  width: 100%;
  height: 50px;
  display: flex;
}

.searchable-list .ais-InstantSearch__root .ais-SearchBox .ais-SearchBox-form input {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
}

.searchable-list .ais-InstantSearch__root .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-submit {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.8rem 0.8rem;
  font-size: 0.81rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  border: 0;
  border-radius: 0.125rem;
  cursor: pointer;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  background-color: #1976d2;
}
.searchable-list .ais-InstantSearch__root .ais-SearchBox .ais-SearchBox-form #search {
  color: white;
}
.searchable-list .ais-InstantSearch__root .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-reset {
  display: none;
}

.searchable-list .ais-Hits-list .ais-InfiniteHits-sentinel {
  position: relative;
  list-style: none;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  top: -10px;
}

.searchable-list .ais-InstantSearch__root .ais-Hits {
  overflow: hidden;
}

.searchableListOptionsMenu {
  position: absolute;
  right: 0;
  background-color: white;
  opacity: 1;
  transform: none;
  transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  transform-origin: 0px 26px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

#Document {
  font-size: 0.8rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  overflow: hidden scroll;
  padding: 20px 20px 100px 20px;
}
#Document * {
  margin: 5px 0px 5px 0px;
}

#Document #title {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

#Document #title #grey-text {
  color: gray;
}

#LetterHead {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

#ProjectHeader {
  display: flex;
  flex-direction: row;
}

#JobDocHeader {
  display: flex;
  flex-direction: row;
}

#ProjectDetails {
  flex-grow: 1;
}

#JobDocDetails {
  flex-grow: 1;
}

#JobHeader {
  display: flex;
  flex-direction: row;
}

#JobDetails {
  flex-grow: 1;
}

#QuoteNumberAndDate {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-left: 5rem;
}

#TitleBar {
  flex-grow: 1;
  text-align: center;
  color: white;
  background-color: rgb(69, 71, 72);
}

/* TABLE LAYOUT */
@media print {
  * {
    float: none !important;
  }
  #Document {
    overflow: visible;
  }
  .App {
    position: relative;
    display: block;
  }
  #root .App .content,
  #root .App .content #panel {
    overflow: visible;
    display: block;
    position: relative;
  }

  #Document table {
    page-break-before: always;
    page-break-inside: avoid;
    break-inside: avoid;
  }
  .projectHeader,
  .side-list,
  .projectLinksList,
  .sideNav,
  .placeHolderTop,
  .placeHolderBottom,
  .nav-pills *,
  #Header,
  #Header *,
  .jobMenu,
  .jobHeader,
  #quoteDiscountButtonJob,
  #quoteDiscountButtonProj,
  #quoteExportButton,
  #quoteExportButtonProj,
  #quoteDiscountButtonJob ul.nav,
  #quoteDiscountButtonProj ul.nav {
    display: none !important;
  }
  #quoteOptionsButtonProj,
  #quoteOptionsButtonJob {
    display: none;
  }
  .Margins {
    display: none !important;
  }
  .NonMargin {
    display: table-cell !important;
  }
}

@media only screen and (max-width: 600px) {
  .doc-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .doc-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.doc-box table {
  break-inside: avoid;
}

.doc-box {
  margin: auto;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.doc-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.doc-box table td {
  padding: 5px;
  vertical-align: top;
}

.doc-box table tr td:nth-child(2) {
  text-align: right;
}

.doc-box table tr.top table td {
  padding-bottom: 20px;
}

.doc-box table tr.top table td.title {
  font-size: 20px;
  line-height: 45px;
  color: #333;
}

.doc-box table tr.information table td {
  padding-bottom: 40px;
}

.doc-box table tr.heading td {
  color: white;
  font-size: 20px;
  border: 2px solid white;
  background: rgb(63, 63, 63);
  font-weight: bold;
  text-align: center;
  page-break-before: always;
}

.doc-box .empty-cell {
  background: white !important;
}

.doc-box .grow {
  width: 100%;
}

.doc-box table tr.sub-heading td,
.doc-box table tr.extras-total-row td {
  background: #eee;
  border: 2px solid rgb(255, 255, 255);
  border-bottom: 4px solid rgb(255, 255, 255);
  font-weight: bold;
  text-align: right;
}

.doc-box table tr.extras-total-row td:first-child,
.doc-box table tr.extras-total-row td:nth-child(2) {
  background-color: white;
}

.doc-box table tr.sub-heading td:nth-child(1) {
  text-align: left;
}

.doc-box table tr.details-header td {
  text-align: left;
  font-weight: bold;
  border-top: 2px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(255, 255, 255);
  padding: 0px 7px 0px 7px;
}

.doc-box table tr.details:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.doc-box table tr.details td {
  text-align: right;
  border-top: 2px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(255, 255, 255);
  padding: 0px 7px 0px 7px;
}

.doc-box table tr.details td:nth-child(1),
.doc-box table tr.details {
  text-align: left;
}

.doc-box table td.align-left {
  text-align: left !important;
}

.doc-box table tr.details td.item-no {
  text-align: center;
}

.doc-box table tr.details td.bold {
  font-weight: bold;
}

.doc-box table tr.total td,
.doc-box table tr.summary-total td {
  background: #eee;
  border: 2px solid rgb(255, 255, 255);
  text-align: right;
}

.doc-box table tr.total td:last-child,
.doc-box table tr.summary-total td:last-child,
.doc-box table tr.summary-total td:nth-child(3) {
  font-weight: bold;
}

.doc-box table tr.bottom td {
  font-style: italic;
  font-size: 12px;
  text-align: right;
  padding-bottom: 50px;
}

.doc-box table tr.job-bottom td {
  font-style: italic;
  font-size: 12px;
  text-align: right;
  padding-right: 2em;
}

.doc-box table tr.total td:nth-child(1),
.doc-box table tr.summary-total td:nth-child(1),
.doc-box table tr.summary-total td.white-cell {
  background-color: white;
}

.doc-box table tr.extras-total-row {
  text-align: right;
}

.doc-box table tr td.padding-cell {
  width: 60%;
  background-color: white;
}

.doc-box table tr td.border-on-top {
  padding: 5px;
  border-top: 3px solid black;
  font-weight: bold;
}

.doc-box table tr.comments td {
  text-align: center;
  font-style: italic;
  padding: 20px;
}

.doc-box table tr.job-comments td {
  text-align: center;
  font-style: italic;
  padding-bottom: 20px;
}

.doc-box table tr.terms-heading td {
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid grey;
}

.doc-box table tr td.term-details {
  font-size: 0.7em;
  font-weight: bold;
  padding-bottom: 100px;
  line-height: 15px;
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

.doc-box table tr.margin-report-totals td {
  font-weight: bold;
  font-size: 1rem;
  text-align: right;
  vertical-align: middle;
}

.doc-box table tr.margin-report-totals td:last-child {
  font-weight: bold;
  font-size: 1.3rem;
  text-align: right;
}

.doc-box table tr.white-sub-heading td {
  padding-top: 30px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.doc-box table tr.notes td {
  font-size: 1rem;
}

.nav-pills {
  background-color: #fcfdff;
  box-shadow: 0px 3px 1px 0px #e9e9e9;
  padding: 10px 0 10px 0;
}

.quote-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.projectInfoContainer .tab-content,
.projectInfoContainer .tab-pane {
  height: 92%;
}

.quote-wrapper .tab-content,
.quote-wrapper .tab-pane {
  height: calc(100% - 30px);
}

.sub-heading-align-right td {
  background: #eee;
  border: 2px solid rgb(255, 255, 255);
  border-bottom: 4px solid rgb(255, 255, 255);
  font-weight: bold;
  text-align: right;
  vertical-align: middle !important;
}

#Document td.bold-cell {
  font-size: 18px;
  font-weight: bold;
}

#Document thead {
  display: contents;
}

#Document .po-letter-head #LetterHead {
  margin-bottom: 50px;
}

#Document .margin-report tr.details td {
  vertical-align: middle;
}

#Document tr.detailed-view td {
  text-align: right !important;
  padding: 0px;
  margin: 0px;
}

#quoteExportButtonProj {
  position: absolute;
  top: 50px;
  right: 20px;
}

#quoteOptionsButtonProj {
  position: absolute;
  top: 50px;
  right: 150px;
}

#quoteExportButton {
  position: absolute;
  top: 83px;
  right: 20px;
}

#quoteOptionsButtonJob {
  position: absolute;
  top: 83px;
  right: 150px;
}

#quoteDiscountButtonJob {
  position: absolute;
  top: 83px;
  right: 288px;
}

#quoteDiscountButtonProj {
  position: absolute;
  top: 50px;
  right: 288px;
}

#quoteHeading {
  letter-spacing: 0.4em;
  text-transform: uppercase;
}

.doc-box td.fixed-width {
  width: 1px;
}

.doc-box td.avoid-wrap {
  white-space: pre-wrap;
}

.tab-pane .no-items {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.tab-pane .no-items h1 {
  margin-top: 15%;
  font-size: 2em;
  color: darkgray;
}

.jobNumber td {
  text-align: right;
  font-style: oblique;
  padding: 0px 7px 0px 7px;
  font-weight: bold;
}

#Document .blind-detailed-view-total-row td {
  font-weight: bold;
}

.doc-box table tr.dark-background {
  background-color: rgb(245, 245, 245);
}

#Document .data-align-right {
  text-align: right;
}

#discount-view-window {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

#discount-view-window form {
  margin-bottom: 100px;
}

#discount-view-table {
  margin: 2%;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 96%;
}

#discount-view-table td,
#discount-view-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#discount-view-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#discount-view-table tbody tr:hover {
  background-color: #ddd;
}

#discount-view-table .grow {
  width: 100%;
}

#discount-view-table thead {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgb(69, 71, 72);
  color: white;
}

#discount-view-cancel-button {
  position: absolute;
  bottom: 10px;
  right: 15px;
}
#discount-view-save-button {
  position: absolute;
  bottom: 10px;
  right: 140px;
}

#discount-view-table .wide {
  min-width: 150px;
}

#discount-view-table .number-cell {
  font-size: smaller;
  min-width: 90px;
}

#discount-view-table .red-cell,
.orange-cell,
.yellow-cell,
.white-cell {
  text-align: center;
}

#discount-view-table .red-cell {
  background-color: #e45454;
}

#discount-view-table .orange-cell {
  background-color: #e48352;
}

#discount-view-table .yellow-cell {
  background-color: #e4dc5d;
}

#discount-view-table .value-padding {
  border: 1px inset #ccc;
}
#discount-view-table .value-padding input {
  text-align: left;
  border: none;
  padding: 0px;
  outline: none;
}

#Document .terms {
  height: 100%;
}

#Document .no-wrap {
  white-space: nowrap;
}

.jobListWrapper {
  position: relative;
  height: 50px;
  padding: 0 !important;
  width: 100%;
}

.jobListName {
  position: absolute;
  bottom: 0px;
}

.jobListNumber {
  position: absolute;
  top: 0px;
  font-size: smaller;
}

.jobListStatus {
  margin-left: 200px;
  font-size: smaller;
  white-space: nowrap;
  line-height: 2;
  text-transform: capitalize;
}

.reorderJobListWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reorderJobList {
  height: 100%;
  overflow: scroll;
  padding: 0;
  list-style-type: none;
}

.reorderJobListItem {
  background-color: white;
  position: relative;
  height: 50px;
  white-space: nowrap;
  margin: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
  padding-left: 2.5rem;
}

#DragAbleIcon {
  cursor: pointer;
  position: absolute;
  left: 10px;
  color: black;
  font-size: 25px;
}

.selectedJob {
  background-color: #fff;
  box-shadow: 0px 0px 40px 5px rgba(0, 187, 255, 0.578);
  /* in order: x offset, y offset, blur size, spread size, color */
  /* blur size and spread size are optional (they default to 0) */
}

#Header {
  background-color: white;
  height: 50px;
  position: relative;
  top: 0;
  width: 100vw;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  text-align: center;
  transition: border-radius 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  z-index: 1;
}

.placeHolderTop {
  background-color: white;
  position: absolute;
  width: 50px;
  height: 25px;
  left: 0px;
  top: 0;
  z-index: 1;
}

.placeHolderBottom {
  background-color: #2682fb;
  position: absolute;
  width: 50px;
  height: 25px;
  left: 0px;
  top: 25px;
  z-index: -1;
}

#Header .placeHolderRight {
  background-color: white;
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0px;
  top: 0px;
  z-index: -1;
}

#Header .HeaderItems {
  color: #2699fb;
  padding: 5px 0;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-content: center;
}

#Header .HeaderItems .TelishadeTitle {
  position: absolute;
  left: 80px;
  top: 0px;
  color: black;
  font-size: 1.5rem;
  margin: 0;
  line-height: 38px;
  font-weight: 400;
}

#Header .HeaderItems .TelishadeSub {
  position: absolute;
  left: 80px;
  top: 20px;
  color: grey;
  font-size: 1rem;
  margin: 0;
  line-height: 38px;
  font-weight: 400;
}

.textPlaceholder {
  position: absolute;
  left: 80px;
  top: 30px;
  height: 10px;
  border-radius: 20px;
  background-color: lightgrey;
  width: 200px;
  animation-name: placeHolder;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.dashboardFabRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dashboardFabRow .MuiFab-extended {
  height: 75px;
  font-size: 1.2rem;
}

@keyframes placeHolder {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}

#HeaderButton {
  position: fixed;
  right: 0;
  top: 0;
  padding: 8px 10px;
  color: #2699fb;
}

.HeaderItems .icon {
  color: #2699fb;
  font-size: 30px;
  margin-left: 20px;
  padding-top: 5px;
  left: 0;
  position: fixed;
}

.HeaderItems .icon:hover {
  color: #2682fb;
  cursor: pointer;
}

.sideNav {
  width: 12rem;
  background-color: #2682fb;
  height: 100vh;
  -webkit-transition: -webkit-transform 0.5s; /* Safari */
  transition: transform 0.5s;
  position: fixed;
  top: 50px;
  left: 0;
}

.sideNav > nav {
  z-index: 100;
  position: relative;
}

.sideNav ul {
  list-style: none;
  padding-left: 0;
  max-height: 100vh;
  margin-top: 0;
}

.sideNavItem {
  margin-top: 1rem;
  padding-left: 20px;
  display: block;
  color: white;
}

.sideNavItem:hover {
  color: rgba(255, 255, 255, 0.76);
}

.content {
  position: relative;
  top: 0;
  transition: transform 0.5s;
  -webkit-transition: -webkit-transform 0.5s;
  overflow: hidden;
}

#Header ul {
  display: inline-block;
  display: -webkit-inline-box;
  padding-top: 16px;
  margin-top: 0;
  top: 0;
}

#Header ul li {
  padding-right: 30px;
}

body {
  overflow: hidden;
  margin: 0px;
}

.subSideNav {
  display: "none";
  width: 6rem;
  background-color: #1d6acf;
  height: 100vh;
  position: fixed;
  top: 00px;
  left: 12rem;
  z-index: 90;
}

.subSideNav.Open {
  display: block;
}

.subSideNav.Closed {
  display: none;
}

.sideNav.Closed {
  transform: translateX(-12rem);
  -webkit-transform: translateX(-12rem);
}

.sideNav.Open {
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

.content.SubNav {
  transform: translateX(18rem);
  -webkit-transform: translateX(18rem);
}

.content.SideNav {
  transform: translateX(12rem);
  -webkit-transform: translateX(12rem);
}

.content.Closed {
  flex-grow: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

.sideNavSelected {
  border-left: 5px solid white;
}

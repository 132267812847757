.reOrder {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  width: 100%;
  overflow: auto;
}

.reorderWorksheetItem {
  background-color: white;
  position: relative;
  height: 50px;
  white-space: nowrap;
  margin: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
  padding-left: 2.5rem;
  padding-top: 15px;
  padding-bottom: 15px;
}

.selectedJob {
  background-color: #fff;
  box-shadow: 0px 0px 40px 5px rgba(0, 187, 255, 0.578);
  /* in order: x offset, y offset, blur size, spread size, color */
  /* blur size and spread size are optional (they default to 0) */
}

.reOrderWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

.reOrderItem {
  display: flex;
  flex-direction: row;
  height: 70px;
  padding: 20px;
  line-height: 30px;
  margin: 0.5rem;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.reOrderItemDragged {
  display: flex;
  flex-direction: row;
  height: 70px;
  padding: 20px;
  line-height: 30px;
  margin: 0.5rem;
  background-color: white;
}

.reorderLi {
  position: relative;
  display: flex;
  flex-direction: column;
}

.reOrderItemPlaceholder {
  display: flex;
  flex-direction: row;
  height: 70px;
  padding: 20px;
  line-height: 30px;
  margin: 0.5rem;
  background-color: rgba(0, 0, 0, 0.16);
}
.reOrderItemPlacer {
  display: flex;
  flex-direction: row;
  height: 10px;
  padding: 0;
  line-height: 30px;
  margin: 0.5rem;
  background-color: rgba(0, 0, 0, 0.16);
}
.reOrderItem:hover {
  background-color: #2698fb28;
  cursor: grab;
}

.reOrderItem:active {
  cursor: grabbing;
}

.placementLine {
  position: absolute;
  top: 0;
  height: 20px;
  background-color: red;
}

.itemNumberInput:hover {
  text-decoration: underline;
  color: blue;
}

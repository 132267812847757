#Dashboard {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: scroll;
}

#Dashboard * {
  margin: 10px;
}

#Dashboard .greeting {
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

#Dashboard .card-body {
  text-align: center;
}

#Dashboard .centered-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .graphContainer {
  background: white;
  padding: 30px 20px 10px 20px;
} */

#panel > .customers {
  display: flex;
  flex-grow: 1;
}

#panel > .customers .side-list {
  width: 20%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

#panel > .customers .selected-customer-window {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#panel > .customers .tab-container {
  height: 100%;
}

#panel > .customers .tab {
  /* position: relative; */
  height: 100%;
  width: 100%;
}

#panel > .customers .customer-details-panel {
  margin: 0.5%;
  flex-grow: 1;
  background-color: white;
  position: relative;
  padding: 0.5%;
  min-height: 34%;
  overflow: hidden;
}
.customer-details-panel .md-form.md-outline {
  margin: 0;
}
.customer-details-panel div {
  width: fit-content;
}
#panel > .customers .tab-window {
  display: flex;
  flex-direction: column;
  min-height: 65%;
}

#panel > .customers .tab-bar {
  padding: 0;
  padding-left: 5px;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  background: #2699fb;
}

#panel > .customers .tab-bar li {
  list-style: none;
  padding: 5px;
  margin-right: 10px;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
}

.customers .empty-contacts,
.customers .empty-tab {
  font-size: 2em;
  color: darkgray;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 5%;
  height: 100%;
  display: flex;
  flex-grow: 1;
}

.tab-bar li.selected {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #f0f2f8;
}

.tab-bar li {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #2699fb;
}

#panel > .customers .branch-list,
.customers .contact-list,
.customers .project-list {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
}

.branches {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.documents {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

#panel > .customers .project-list {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  flex-grow: 1;
}

#panel > .customers .project-list .list-view {
  display: flex;
  flex-grow: 1;
}

#panel > .customers .document-list {
  display: flex;
  overflow: hidden;
  height: 100%;
}

#panel > .customers .document-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#panel > .customers .document-details .text-holder {
  flex-direction: column;
  padding: 5px;
}

#panel > .customers .document-details .preview-holder {
  font-size: 2em;
  color: whitesmoke;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 10%;
  min-height: 300px;
  background-color: gainsboro;
  display: flex;
  flex-grow: 1;
}

#panel > .customers .communications-tba {
  font-size: 2em;
  color: darkgray;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 10%;
  min-height: 300px;
  display: flex;
  flex-grow: 1;
}

.branch-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.project-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  flex-grow: 1;
  position: relative;
}

.contact-container {
  height: 97%;
  width: 60%;
}

/* @keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
} */

#panel .customers .contact-header,
.customers .branch-header,
.customers .project-header {
  color: white;
  justify-content: center;
  margin: 0;
  padding: 2px;
  background-color: #2682fb;
}

#panel .customers .contact-header h6,
.customers .branch-header h6,
.customers .project-header h6 {
  margin: 0.3rem;
}

.customers .list-view {
  flex-grow: 1;
}

.customers .branch-tab {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.customers .searchable-list {
  flex-grow: 1;
  display: flex;
}

.customers .branch-tab .searchable-list {
  flex-grow: 1;
  text-align: left;
  height: 97%;
}

.customers #custEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.customers #custDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.customers #addBranchPlusBtn {
  position: absolute;
  font-size: 30px;
  bottom: 25px;
  right: 30px;
  color: #2699fb;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.customers #addContactPlusBtn {
  position: absolute;
  font-size: 30px;
  bottom: 50px;
  right: 30px;
  color: #2699fb;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.customers #brEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.customers #brDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.customers #contactEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.customers #contactDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.customers .searchable-list .ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.customers .searchable-list .ais-InstantSearch__root .ais-SearchBox {
  display: flex;
}
.customers .searchable-list .ais-InstantSearch__root .ais-Hits {
  display: flex;
  flex-grow: 1;
  height: auto;
  position: relative;
}
.customers .searchable-list .ais-InstantSearch__root .ais-Hits .ais-Hits-list {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.cust-projects-tab {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.customer-details-panel .no-customer-selected {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.customer-details-panel .no-customer-selected h2 {
  margin-top: 25%;
  font-size: 2em;
  color: darkgray;
}

.customers ul.ais-Hits-list .customerListElement {
  position: relative;
  padding: 10px 20px 20px 10px;
}

.customerListElement .title {
  font-size: 20px;
  font-weight: bolder;
}

.customerListElement .details {
  text-transform: capitalize;
}

.fabButton {
  width: 90px;
}

#root .App {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
#content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#panel {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.loginContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 800px) {
  .filler {
    padding-top: 45vh;
    font-size: 50px;
    width: 50%;
    background: #2699fb;
    color: white;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  .Login {
    position: relative;
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .filler {
    padding-top: 50vh;
    width: 30%;
    background: #2699fb;
    color: white;
    justify-content: center;
    text-align: center;
  }
  .Login {
    position: relative;
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .filler {
    width: 0%;
    background: #2699fb;
    color: white;
    justify-content: center;
    text-align: center;
  }
  .Login {
    position: relative;
    width: 100%;
    overflow: scroll;
  }
}

.login-form {
  justify-self: center;
  align-self: center;
  min-height: 530px;
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-top: 5rem;
  height: fit-content;
}

.loginBtn {
  margin-top: 20px;
  align-self: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.loginHeader {
  background: #2682fb;
  color: white;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 1rem;
  margin-top: -2rem;
}
.loginBtn button {
  width: 100%;
}

.login-form .formRow {
  margin-bottom: 40px;
  position: relative;
}

.loginError {
  color: red;
  position: absolute;
  top: 2rem;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.loginErrorNone {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.forgotPWLink {
  padding-top: 5px;
  color: #2682fb;
  cursor: pointer;
}

.forgotPWLink:hover {
  color: #62a5fd;
}

.errorMessage {
  color: red;
  transition: visibility 0s, opacity 0.5s linear;
}

h1,
h2,
h3,
h4,
h4 {
  text-transform: capitalize;
}

#panel > .products {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
#panel > .products ul.list-view {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
#panel > .products .product-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#panel {
  background-color: white;
}

.blinds {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.curtains {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.productComponentList {
  display: flex;
  width: 9rem;
  background-color: #2699fb;
  margin-top: 5px;
  color: white;
  display: block;
}
.productComponentList ul {
  margin: 0;
  padding: 0;
}

.productComponentList ul li a {
  margin-top: 1rem;
  padding-left: 20px;
  display: block;
  color: white;
}

.productComponentList ul li a:hover {
  color: rgba(255, 255, 255, 0.76);
}

.productContainer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.productComponentHeader {
  display: flex;
  height: 50px;
  width: 100%;
  background-color: white;
  padding-left: 2.5rem;
  z-index: 1;
}

.productComponentHeader h2 {
  margin: 0;
  padding: 0;
  align-self: center;
}

.productList {
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
}

.productList ul li {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #f0f2f8;
}

.productList ul li:hover {
  background: #f0f2f8;
}

.productList .list-view {
  width: 100%;
  height: 100%;
}

.productComponentHeader #plusCircle {
  color: #2699fb;
  cursor: pointer;
  align-self: center;
  right: 5px;
  position: fixed;
  font-size: 30px;
  height: 50px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}

.productDetailHeader {
  z-index: 30;
  position: fixed;
  display: flex;
  height: 50px;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-bottom: 3px;
  padding-left: 15px;
}

.productDetailHeader #backArrow {
  color: #2699fb;
  align-self: center;
  font-size: 30px;
  margin-right: 15px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.productDetailHeader h2 {
  margin-bottom: 0px;
  align-self: center;
  font-weight: bolder;
}

.detailsContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.detailsLeft {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.detailsName {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem;
}

.coloursList {
  display: flex;
  max-width: 100%;
  flex-grow: 1;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  height: 100%;
}

.detailsPricing {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem;
}

.detailsPhoto {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 300px;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f0f2f8;
}

.detailsDatasheets {
  display: flex;
  width: 66%;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-right: 2rem;
  padding: 1rem;
}

.sideNavSelected {
  border-left: 5px solid white;
}

.detailsName .md-form.md-outline {
  margin: 0;
}

@keyframes popupAnimation {
  from {
    top: calc(50vh - 200px);
    opacity: 0;
  }
  to {
    top: calc(50vh - 100px);
    opacity: 1;
  }
}

.popupContainer {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(231, 147, 147, 0.16);
}

.popupWindow {
  align-content: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #f0f2f8;
  width: 500px;
  height: 200px;
  position: fixed;
  top: calc(50vh - 100px);
  left: calc(50vw - 250px);
  animation-name: popupAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.popupHeader {
  color: white;
  background-color: #2699fb;
  align-content: center;
  text-align: center;
}

.popUpButtonContainer {
  align-content: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.popUpTextContainer {
  align-content: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding-top: 10%;
  width: 100%;
  height: 55%;
}

.needs-validation {
  width: 100%;
}

.gridTextArea {
  height: inherit;
  resize: none;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pricelistContainer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.pricelistHeader {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  background-color: #2682fb;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 20px;
  padding-top: 10px;
  color: white;
  position: relative;
}

#pgMarkup {
  height: 30px;
  width: 30px;
  display: initial;
  padding: 0;
}

.pricelistHeader div {
  margin: 0 1rem;
}

.pricelist {
  height: calc(100% - 50px);
}

.react-grid-multiline-header {
  height: 100%;
}

.pricelist .react-grid-Container {
  height: 100%;
  width: 100% !important;
}

.pricelist .react-grid-Grid {
  height: 100%;
}

.pricelist .react-grid-Main {
  height: 100%;
}

.pricelist .react-grid-Canvas {
  width: 100% !important;
}

.priceListEdit {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pricelist .btn {
  position: absolute;
  top: 0;
}

#plEditBtn {
  right: 144px;
  padding: 7px 10px;
  color: white;
}

#plDeleteBtn {
  right: 72px;
  padding: 7px 10px;
  color: white;
}

#plSaveBtn {
  right: 0;
  padding: 7px 16px;
  color: white;
}

.productList .list-view li {
  position: relative;
}

.productList .list-view li .type {
  position: absolute;
  left: 400px;
  top: 0.6rem;
  text-transform: capitalize;
  color: #495057;
  font-style: italic;
  text-align: center;
}

.productList .list-view li .name {
  position: inherit;
  left: 0%;
  text-transform: capitalize;
  color: #495057;
  font-size: 1.2rem;
  text-align: center;
}

.card.detailsPricing .searchable-list .ais-Hits .ais-Hits-list {
  overflow: hidden;
}

.card.detailsPricing .searchable-list .ais-Hits .ais-Hits-list .ais-Hits-item .manufacturer {
  padding: 0;
}

.coloursList {
  position: relative;
  max-height: 700px;
}

#ColoursListAdd {
  position: absolute;
  top: 5px;
  right: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  height: 35px;
  padding-top: 9px;
}
.coloursListElement {
  position: relative;
  height: 80px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  line-height: 80px;
}
.coloursListList {
  margin-top: 20px;
  overflow: auto;
}

.coloursListElement .colourSquareElement {
  height: 60px;
  width: 60px;
  margin: 10px;
  background-color: #ced4da;
}

.coloursListElement #ColoursListEdit {
  margin-top: 15px;
  margin-bottom: 15px;
  margin: 15px 15px;
}

.coloursListElement #ColoursListRemove {
  margin-top: 15px;
  margin-bottom: 15px;
}

.coloursListElement .colourListButtons {
  position: absolute;
  right: 0;
  margin-right: 20px;
}

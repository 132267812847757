#panel > .manufacturers {
  display: flex;
  flex-grow: 1;
}

#panel > .manufacturers .side-list {
  width: 20%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

#panel > .manufacturers .selected-manufacturer-window {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#panel > .manufacturers .tab-container {
  height: 100%;
}

#panel > .manufacturers .tab {
  height: 100%;
  width: 100%;
}

#panel > .manufacturers .manufacturer-details-panel {
  margin: 0.5%;
  flex-grow: 1;
  background-color: white;
  position: relative;
  padding: 0.5%;
  height: 34%;
  overflow: hidden;
}
.manufacturer-details-panel .md-form.md-outline {
  margin: 0;
}
.manufacturer-details-panel div {
  width: fit-content;
}
#panel > .manufacturers .tab-window {
  display: flex;
  flex-direction: column;
  min-height: 65%;
}

#panel > .manufacturers .tab-bar {
  padding: 0;
  padding-left: 5px;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  background: #2699fb;
}

#panel > .manufacturers .tab-bar li {
  list-style: none;
  padding: 5px;
  margin-right: 10px;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
}

.tab-bar li.selected {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #f0f2f8;
}

.tab-bar li {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #2699fb;
}

#panel > .manufacturers .branch-list,
.manufacturers .contact-list {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
}

.branches {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.manufacturers .branch-container {
  width: 40%;
}

.manufacturers .contact-container {
  width: 60%;
}

#panel .manufacturers .contact-header,
.manufacturers .branch-header {
  color: white;
  justify-content: center;
  margin: 0;
  padding: 2px;
  background-color: #2682fb;
}

#panel .manufacturers .contact-header h6,
.manufacturers .branch-header h6 {
  margin: 0.3rem;
}

.manufacturers .list-view {
  flex-grow: 1;
}

.manufacturers .branch-tab {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.manufacturers .searchable-list {
  flex-grow: 1;
  display: flex;
}

.manufacturers .branch-tab .searchable-list {
  flex-grow: 1;
  text-align: left;
  height: 100%;
}

.manufacturers #custEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.manufacturers #custDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.manufacturers #addBranchPlusBtn {
  position: absolute;
  font-size: 30px;
  bottom: 50px;
  right: 30px;
  color: #2699fb;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.manufacturers #addContactPlusBtn {
  position: absolute;
  font-size: 30px;
  bottom: 50px;
  right: 30px;
  color: #2699fb;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.manufacturers #brEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.manufacturers #brDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.manufacturers #contactEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.manufacturers #contactDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.manufacturers .empty-contacts {
  font-size: 2em;
  color: darkgray;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 5%;
  height: 100%;
  display: flex;
  flex-grow: 1;
}

.searchable-list .ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.searchable-list .ais-InstantSearch__root .ais-SearchBox {
  display: flex;
}
.searchable-list .ais-InstantSearch__root .ais-Hits {
  display: flex;
  flex-grow: 1;
  height: auto;
  position: relative;
}
.manufacturers .searchable-list .ais-InstantSearch__root .ais-Hits .ais-Hits-list {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.manufacturers p b {
  font-size: 1rem;
}

.manufacturers p em {
  font-size: 0.8rem;
  padding-bottom: 18px;
  font-style: normal;
}

.jobNotes {
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.jobNotes .notesDisplay {
  height: calc(100% - 50px);
  width: 100%;
  justify-content: flex-end;
  align-content: flex-end;
  overflow: auto;
}
.timeStamp {
  color: lightgrey;
  right: 0;
  left: auto;
  float: right;
  clear: both;
  height: 100px;
  text-align: center;
  line-height: 100px;
  padding: 0 1rem;
}
.jobNotes .notesDisplay .noteDisplay {
  width: fit-content;
  overflow: hidden;
  height: 100px;
  padding-left: 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  line-height: 100px;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
  position: relative;
}

.jobNotes .notesDisplay .noteDisplay p {
  line-height: 1;
  word-break: break-all;
  display: inline-block;
  vertical-align: middle;
}

.noteWrapper {
  margin-bottom: 1rem;
}

.jobNotes .notesEntry {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  align-content: flex-end;
  justify-content: flex-end;
  text-align: right;
  background-color: #2682fb;
  display: flex;
}

.jobNotes .notesEntry #noteField {
  width: 300px;
  height: 50px;
  border-color: #2682fb;
  border-style: solid;
  margin: 0;
  text-align: center;
  resize: none;
  overflow: auto;
}

.jobNotes #editBtn {
  top: -39px;
  padding: 1px 5px;
  right: -4px;
}

.jobNotes #deleteBtn {
  top: -39px;
  padding: 1px 5px;
  right: -4px;
}

.jobNotes #submitNoteBtn {
  padding: 16px 50px;
  margin: 0;
}

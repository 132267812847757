.tab-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.tab-container ul.tab-bar {
  padding: 0;
  margin: 0;
  padding-left: 17.5%;
  display: flex;
  box-sizing: border-box;
  background: #2699fb;
  align-content: center;
}

.tab-container .tab {
  display: flex;
  overflow: hidden;
}

.tab-container ul.tab-bar li {
  list-style: none;
  padding: 5px;
  margin-right: 20%;
  color: white;
  cursor: pointer;
  text-transform: capitalize;
}

.tab-container ul.tab-bar li:last-of-type {
  margin: 0;
}

.tab-container ul.tab-bar li.selected {
  border-bottom: 5px solid white;
}

.tab-container .selected {
  -webkit-transition: all 0.2s ease-in-out; /* Safari */
  transition: all 0.2s ease-in-out;
}

.myaccount-container {
  justify-content: center;
  align-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.myaccount-card {
  position: relative;
  margin: 2rem;
  width: 90%;
  min-width: 800px;
  max-width: 1000px;
  height: 90%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
}

.myaccount-details {
  margin-top: 2rem;
  text-align: center;
  justify-content: center;
}
/* .editBtn {
  position: absolute;
  top: 0;
} */

.passwordFields {
  margin-bottom: 2rem;
  position: relative;
}

.myaccount-card .editBtn {
  width: 100%;
  right: 0;
  padding: 7px 10px;
  color: white;
}

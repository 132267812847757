.PurchaseOrderWrapper {
  height: 100%;
}

.purchaseOrderDocument {
  font-size: 0.8rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100% - 50px) !important;
  overflow: hidden scroll;
  padding: 20px 20px 100px 20px;
}

.PurchaseOrderWrapper .manufactuerTabBar {
  position: relative;
  display: flex;
  flex-direction: row;
}
.PurchaseOrderWrapper .manufactuerTabBar .MuiTabs-root {
  flex-grow: 1;
}

.PurchaseOrderWrapper .manufactuerTabBar .exportButton {
  /* background-color: green; */
  color: white;
  padding: 0 1rem;
}

.PurchaseOrderWrapper .manufactuerTabBar .optionsButton {
  /* background-color: grey; */
  color: white;
  padding: 0 1rem;
}

.doc-box-po {
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.captilizeData {
  text-transform: capitalize;
}

.pageTemplate {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 1rem;
  color: black;
}

.pageTemplate .pageNumber {
}

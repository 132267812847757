@keyframes popup2Animation {
  from {
    margin-top: 0vh;
    opacity: 0;
  }
  to {
    margin-top: 5vh;
    opacity: 1;
  }
}

.popupFormContainer {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.16);
  overflow: none;
}

.popupFormWindow {
  display: flex;
  align-content: center;
  flex-direction: column;
  min-width: 33vw;
  width: 50vw;
  max-height: 85vh;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #fefeff;
  margin-top: 5vh;
  margin-left: 25vw;
  margin-bottom: 10vh;
  animation-name: popup2Animation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.popupFormHeader {
  color: white;
  background-color: #2699fb;
  align-content: center;
  text-align: center;
  width: 100%;
}

.popupFormButtonContainer {
  align-content: right;
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 15px;
}

.popupFormTextContainer {
  align-content: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 2%;
}
.popupForm {
  display: flex;
  flex-flow: row wrap;
  padding: 15px;
  height: auto;
  bottom: 0;
  overflow: auto;
}

.popupFormInputField {
  width: 50%;
  padding: 0px 5px 0px 5px;
}

.popupForm .detailFields {
  position: relative;
}

.popupForm .datefieldLabel {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}

.datefield {
  padding-top: 7px;
  display: flex;
  flex-direction: column;
}

.datefield .datefieldLabel {
  margin-bottom: 3px;
}

.popup-subheading {
  width: 100%;
  flex-grow: 1;

  margin-bottom: 5px;
}

.popup-subheading h5 {
  padding: 30px 0 0 5px;
  border-bottom: 1px solid lightgray;
}

#panel > .staff {
  display: flex;
  flex-grow: 1;
}

#panel > .staff .side-list {
  width: 20%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

#panel > .staff .selected-staff-window {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#panel > .staff .tab-container {
  height: 100%;
}

#panel > .staff .tab {
  /* position: relative; */
  height: 100%;
  width: 100%;
}

#panel > .staff .staff-details-panel {
  margin: 0.5%;
  flex-grow: 1;
  background-color: white;
  position: relative;
  padding: 0.5%;
  max-height: 34%;
  overflow: hidden;
}
.staff-details-panel .md-form.md-outline {
  margin: 0;
}
.staff-details-panel div {
  width: fit-content;
}
#panel > .staff .tab-window {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#panel > .staff .tab-bar {
  padding: 0;
  padding-left: 5px;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  background: #2699fb;
}

#panel > .staff .tab-bar li {
  list-style: none;
  padding: 5px;
  margin-right: 10px;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
}

.tab-bar li.selected {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #f0f2f8;
}

.tab-bar li {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #2699fb;
}

.staff .empty-component {
  font-size: 2em;
  color: darkgray;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 5%;
  height: 100%;
  display: flex;
  flex-grow: 1;
}

#panel > .staff .address-list,
.staff {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
}

.addresses {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.documents {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

#panel > .staff .project-list {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  flex-grow: 1;
}

#panel > .staff .project-list .list-view {
  display: flex;
  flex-grow: 1;
}

#panel > .staff .document-list {
  display: flex;
  overflow: hidden;
  height: 100%;
}

#panel > .staff .document-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#panel > .staff .document-details .text-holder {
  flex-direction: column;
  padding: 5px;
}

#panel > .staff .document-details .preview-holder {
  font-size: 2em;
  color: whitesmoke;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 10%;
  min-height: 300px;
  background-color: gainsboro;
  display: flex;
  flex-grow: 1;
}

#panel > .staff .communications-tba {
  font-size: 2em;
  color: darkgray;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 10%;
  min-height: 300px;
  display: flex;
  flex-grow: 1;
}

.address-container {
  width: 40%;
  position: relative;
}

#panel .staff .address-header {
  color: white;
  justify-content: center;
  margin: 0;
  padding: 2px;
  align-self: center;
  /* flex-grow: 1; */
  background-color: #65a7f1;
  /* animation: 0.3s ease-out 0s 1 slideInFromTop; */
}

#panel .staff .address-header h6 {
  margin: 0.3rem;
}

.staff .list-view {
  flex-grow: 1;
}

.staff .address-tab {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.staff .searchable-list {
  flex-grow: 1;
}

.staff .address-tab .searchable-list {
  flex-grow: 1;
  text-align: left;
}

.staff #custEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.staff #custDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.staff #addAddressPlusBtn {
  position: absolute;
  font-size: 30px;
  bottom: 15px;
  right: 15px;
  color: #2699fb;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.staff #addContactPlusBtn {
  position: absolute;
  font-size: 30px;
  bottom: 50px;
  right: 30px;
  color: #2699fb;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

.staff #brEditBtn {
  position: absolute;
  padding: 7px;
  right: 45px;
  top: 0;
  margin-right: 0.5rem;
}

.staff #brDeleteBtn {
  position: absolute;
  padding: 7px 14px 7px 14px;
  right: 0;
  top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

#panel > .staff .tba {
  font-size: 2em;
  color: darkgray;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 5%;
  /* min-height: 300px; */
  display: flex;
  flex-grow: 1;
}

.searchable-list .ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.searchable-list .ais-InstantSearch__root .ais-SearchBox {
  display: flex;
}
.searchable-list .ais-InstantSearch__root .ais-Hits {
  display: flex;
  flex-grow: 1;
  height: auto;
  position: relative;
}
.staff .searchable-list .ais-InstantSearch__root .ais-Hits .ais-Hits-list {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

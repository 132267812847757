#panel > .projects {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.projects .ais-InstantSearch__root,
.ais-Hits {
  height: 100%;
  width: 100%;
}

.projects .side-list .searchable-list .ais-InstantSearch__root .ais-Hits {
  height: 100%;
}

.projects ul.ais-Hits-list {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.projects ul.ais-Hits-list li {
  position: relative;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
}

.projects ul.ais-Hits-list p {
  padding: 10px 20px 20px 10px;
  margin: 0px;
}

.projects .side-list {
  width: 350px;
}

.list-view li {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #f0f2f8;
}

.projectList {
  max-height: calc(100% - 50px);
}

.tab-container ul.tab-bar {
  padding: 0;
  margin: 0;
  padding-left: 17.5%;
  display: flex;
  box-sizing: border-box;
  background: #2682fb;
  align-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.tab-container ul.tab-bar li {
  list-style: none;
  padding: 5px;
  margin-right: 20%;
  color: white;
  cursor: pointer;
}

.projects {
  display: flex;
  flex-direction: row;
}

.projectContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.projectHeader {
  display: flex;
  height: 50px;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-bottom: 3px;
  padding-left: 2.5rem;
}

.projectLinksList ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.projectHeader h2 {
  margin: 0;
  padding: 0;
  align-self: center;
}

.projectInfoContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 50px);
}

.projectLinksList {
  display: flex;
  width: 215px;
  background-color: #2699fb;
  color: white;

  /* DISABLE ABLITY TO SELECT TEXT */
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.projectLinksList ul {
  width: 215px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.projectLinksList ul li a,
.projectLinksList ul li .menu-item-text {
  margin-top: 1rem;
  padding-left: 20px;
  padding-right: 10px;
  display: block;
  color: white;
}

.projectLinksList ul li a:hover,
.projectLinksList ul li .menu-item-text:hover {
  color: rgba(255, 255, 255, 0.76);
  cursor: pointer;
}

.subMenuLinksList ul {
  padding: 0;
}

.subMenuLinksList ul:first-child {
  margin-top: 8px;
}

.subMenuLinksList-expanded ul:first-child {
  margin-top: 0px;
}

.subMenuLinksList ul li {
  width: 100%;
  background-color: #2682fb;
  color: white;
  padding: 0.5rem 0 0.5rem 30px;
}

.subMenuLinksList ul li:hover {
  color: rgba(255, 255, 255, 0.76);
  cursor: pointer;
}

.subMenuLinksList-expanded ul li {
  display: flex;
  flex-grow: 1;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  background-color: #f5f9fd;
  list-style: none;
}

.subMenuLinksList-expanded ul li a {
  padding: 8px 0 8px 45px;
  flex-grow: 1;
  /* padding-left: 38px; */
  display: block;
  color: #495057;
  margin: 0;
}

.subMenuLinksList-expanded ul li .available-documents {
  float: right;
  margin-right: 5px;
}

.subMenuLinksList-expanded ul li .not-implemented {
  padding: 8px 0 8px 45px;
  flex-grow: 1;
  /* padding-left: 38px; */
  display: block;
  color: #ababab;
}

.subMenuLinksList-expanded ul li .not-implemented:hover {
  cursor: auto;
}

.subMenuLinksList-expanded ul li a:hover {
  color: rgba(23, 100, 243, 0.76);
  cursor: pointer;
}

.projectDetailsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.projectDetailsContainer .jobList {
  overflow: hidden;
}

.projectDetails {
  position: relative;
  display: flex;
  padding-left: 2rem;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  /* height: 30%; */
  background-color: white;
  /* border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.16); */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.jobList {
  display: flex;
  width: 100%;
  flex-grow: 1;
  /* height: 100%; */
  flex-direction: column;
}
.jobList .searchable-list {
  height: 100%;
}

.jobList ul li {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: #f0f2f8;
}

.jobList ul li:hover {
  background: #f0f2f8;
}

.jobList .list-view {
  width: 100%;
}
.jobContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.jobContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.jobHeader {
  display: flex;
  height: 80px;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-bottom: 3px;
  padding-left: 15px;
}

.jobHeader h2 {
  margin-bottom: 0px;
  align-self: center;
  font-weight: bolder;
}

.jobHeader #backArrow {
  color: #2699fb;
  align-self: center;
  font-size: 30px;
  margin-right: 15px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

#jobBtn {
  position: fixed;
  right: 0;
  padding: 7px 10px;
  color: white;
}

#jobBtn2 {
  position: fixed;
  right: 75px;
  padding: 7px 10px;
  color: white;
}

.jobMenu {
  display: flex;
  width: 215px;
  background-color: #2699fb;
  color: white;

  /* DISABLE ABLITY TO SELECT TEXT */
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.jobMenu ul {
  margin: 0;
  padding: 0;
}

.productListImage {
  background-color: grey;
  min-width: 50px;
  min-height: 50px;
  height: 15vh;
  width: 15vh;
  margin-right: 2rem;
}
.jobCheckbox {
  align-self: center;
  width: 20px;
  height: 20px;
  margin-right: 2rem;
}
.name {
  display: flex;
}

.jobProductList {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
  box-sizing: border-box;
}

.jobProductList .searchable-list {
  height: calc(100% - 25px);
}

.jobProductList .BlindsProductList {
  flex-grow: 1;
  height: 100%;
}

.jobProductList .CurtainsProductList {
  flex-grow: 1;
  height: 100%;
}

.jobProductList .list-view {
  height: 100%;
}

.jobProductList .ProductListHeader {
  flex-grow: 1;
  background-color: #2699fb;
  color: white;
  padding-left: 20px;
  font-size: larger;
  font-weight: bolder;
  z-index: 51;
}

#editButton {
  position: absolute;
  right: 0;
  top: 0;
  color: grey;
  font-size: 15px;
  margin-right: 0.5rem;
  cursor: pointer;
}

#acceptButton {
  position: absolute;
  display: grid;
  right: 0;
  top: 0;
  color: green;
  font-size: 15px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.projectDetails .md-form.md-outline {
  margin: 0;
}

.projectDetails .detailFields {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0 0 0.5rem 0;
  position: relative;
}

.projectDetails .DateFields {
  display: flex;
  flex-direction: column;
  margin: 0 0 0.5rem 0;
}

.datefieldLabel {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}

.select-default {
  color: #ababab;
}

.projectContent #addJobButton {
  width: 90px;
  position: absolute;
  font-size: 30px;
  bottom: 5px;
  right: 19px;
  color: #2699fb;
  /* -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16)); */
  cursor: pointer;
}
.searchBar {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.searchBar input {
  height: 50px;
  width: calc(100% - 50px);
}

.searchBar .md-form.md-outline {
  margin: 0px;
  width: 100%;
}

.searchBar .md-form.md-outline .form-control {
  margin: 0px;
}

.searchBar .form-control {
  margin: 0px;
}

.searchBar span {
  background-color: #2682fb;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-content: center;
  text-align: center;
  display: table-cell;
}

#SearchIcon {
  color: white;
  font-size: 20px;
  margin: 15px;
}

#editBtn {
  right: 0;
  padding: 7px 10px;
  color: white;
}

.editField {
  padding-right: 100px;
}

.worksheetContainer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.worksheetHeader {
  height: 50px;
  width: 100%;
  background-color: #2682fb;
  text-align: center;
  font-size: 20px;
  padding-top: 10px;
  color: white;
  position: relative;
}

.worksheetHeader select {
  background: transparent;
  color: white;
  line-height: 1;
  border: 0;
  padding: 0;
  border-radius: 0;
  position: relative;
  z-index: 10;
  font-size: 20px;
  cursor: pointer;
  margin: 0px 20px;
}

.worksheetHeader select option {
  color: black;
}

.worksheet {
  height: calc(100% - 50px);
}

.worksheet .react-grid-Canvas {
  padding-bottom: 250px;
}

.react-grid-multiline-header {
  height: 100%;
}

.worksheet .react-grid-Container {
  height: 100%;
}

.worksheet .react-grid-Grid {
  height: 100%;
}

.worksheet .react-grid-Main {
  height: 100%;
}

#backArrowWorksheet {
  position: absolute;
  left: 15px;
  top: 0;
  margin-top: 10px;
  justify-self: center;
  align-self: center;
  font-size: 30px;
  margin-right: 15px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}

#AddItemBtn {
  position: absolute;
  right: 415px;
  top: 0;
  padding: 9px 15px;
  color: #2682fb;
}

#DuplicateItemBtn {
  position: absolute;
  right: 275px;
  top: 0;
  padding: 9px 15px;
  color: #2682fb;
}

#MultiSelectBtn {
  position: absolute;
  right: 155px;
  top: 0;
  padding: 9px 15px;
  color: #f0f2f8;
}

#CancelBtn {
  position: absolute;
  right: 155px;
  top: 0;
  padding: 9px 30px;
  color: #f0f2f8;
}

#DeleteBtn {
  position: absolute;
  right: 70px;
  top: 0;
  padding: 9px 15px;
  color: #f0f2f8;
}

#SaveBtn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 9px 15px;
  color: #f0f2f8;
}

#ReorderBtn {
  position: absolute;
  left: 85px;
  top: 0;
  padding: 9px 15px;
  color: #2682fb;
  background-color: white !important;
}

#DuplicateProjectBtn {
  right: 0;
  position: absolute;
  padding: 8px 10px;
}

.WorksheetNumberInput {
  height: inherit;
  resize: none;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.WorksheetEditor .ais-InstantSearch__root ul {
  max-height: 150px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
  background: white;
  text-align: left;
  border-right: 1px solid #2699fb;
  border-bottom: 1px solid #2699fb;
  border-left: 1px solid #2699fb;
}

.WorksheetEditor .ais-InstantSearch__root ul li {
  padding: 3px 0px;
  padding-left: 3px;
  cursor: pointer;
}

.WorksheetEditor .ais-InstantSearch__root ul li:hover {
  background: #2699fb;
  color: white;
}

.projectDetails ul.ais-Hits-list p {
  padding: 0;
}

.projectDetails ul.ais-Hits-list li {
  border: none;
}

.projectDetails
  .searchable-list
  .ais-InstantSearch__root
  .ais-Hits
  ul.ais-Hits-list {
  overflow-y: auto;
}

.unimplemented {
  color: rgba(255, 255, 255, 0.5) !important;
  cursor: default !important;
}

.projects .side-list {
  height: 100%;
}

.projects .side-list .projectList {
  height: 100%;
}

.projects .side-list .projectList .tab {
  flex-grow: 1;
}

.projects ul.ais-Hits-list .projectListElement {
  position: relative;
  padding: 10px 20px 30px 10px;
}

.projectListElement .title {
  font-size: 20px;
  font-weight: bolder;
}

.projectListElement .date {
  position: absolute;
  bottom: 0;
  right: 10px;
}

.projectListElement .status {
  position: absolute;
  bottom: 0;
  left: 10px;
  text-transform: capitalize;
}

.projectDetails div {
  text-transform: capitalize;
}

.projects ul.ais-Hits-list .jobItemListElement {
  position: relative;
  padding: 10px 20px 55px 10px;
}

.projects ul.ais-Hits-list .jobItemListElement .pricingFail {
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-weight: bolder;
  color: rgba(245, 78, 66, 1);
}

.projects ul.ais-Hits-list .jobItemListElement .pricingPending {
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-weight: bolder;
  color: rgba(66, 245, 158, 1);
}

.projects ul.ais-Hits-list .jobItemListElement .pricingSuccess {
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-weight: bolder;
  color: #2b2f32;
}

.jobItemListElement .itemNo {
  font-size: 10px;
  font-weight: bolder;
}

.jobItemListElement .location {
  font-size: 20px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-weight: bolder;
}

.jobItemListElement .width {
  position: absolute;
  left: 10px;
  bottom: 32.5px;
}

.jobItemListElement .drop {
  position: absolute;
  left: 120px;
  bottom: 32.5px;
}

.jobItemListElement .drive {
  text-transform: capitalize;
  position: absolute;
  left: 240px;
  bottom: 32.5px;
}

.jobItemListElement .driveSide {
  text-transform: capitalize;
  position: absolute;
  left: 360px;
  bottom: 32.5px;
}

.jobItemListElement .opening {
  text-transform: capitalize;
  position: absolute;
  left: 360px;
  bottom: 32.5px;
}

.projects .toaster {
  z-index: 5000;
  position: absolute;
  height: 50px;
  background-color: red;
  color: white;
  bottom: 0;
  margin: 1rem;
  width: auto;
  padding: 1rem;
  justify-content: center;
  line-height: calc(50px - 2rem);
  border-radius: 10px;
  animation-name: toastAppear;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

@keyframes toastAppear {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.unsaved {
  color: red;
}

.saving {
  color: yellow;
}

.saved {
  color: white;
}

.projects .modal-body-text {
  white-space: pre;
}

.custom2stagebtnWrapper .customFabOption1 {
  position: absolute;
  width: auto;
  white-space: nowrap !important;
  right: 60px;
  transition: all 0.5s ease !important;
}

.custom2stagebtnWrapper .customFabOption2 {
  background-color: #2682fb;
  width: auto;
  white-space: nowrap !important;
  position: absolute;
  right: 60px;
  transition: all 0.5s ease !important;
}

.custom2stagebtnWrapper {
  width: 90px;
  height: 90px;
  font-size: 30px;
  bottom: 5px;
  right: 19px;
  position: absolute;
}

.fabButton {
  transition: all 0.5s ease;
  z-index: 500;
}

.projectDetails .searchable-list {
  flex-grow: 1;
}
.jobProductList .ProductListHeader {
  flex-grow: initial;
}
